import * as React from "react"
import {Routes, Route, useLocation, Navigate, Router,} from "react-router-dom"
import AuthProvider, {useAuth} from "./contexts/authContext"

import {
    AuthScreen, ChatScreen, CodeScreen,
    HomeScreen, MessageScreen, StudentScreen,
} from './screens'

export default function App() {

  return (
      <AuthProvider>
        <Routes>
            <Route path="/auth" element={<AuthScreen />} />
            <Route path="/" element={<Private><HomeScreen /></Private>} />
            <Route path="/messages" element={<Private><MessageScreen /></Private>} />
            <Route path="/messages/:messageId" element={<Private><ChatScreen /></Private>} />
            <Route path="/students/:studentId" element={<Private><StudentScreen /></Private>} />
        </Routes>
      </AuthProvider>
  );
}

function Private({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  console.log('auth', auth)

  if (! auth.logged) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children;
}

