import * as React from "react";
import styles from './Student.module.css'
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

type StudentProfileType = {
    imgUrl?: string,
    fullName?: string,
    humanInfo?: string,
}

export default function StudentProfile({imgUrl, fullName, humanInfo} : StudentProfileType) {
    return (
        <div className='my-2 rounded border-bottom d-flex bg-light py-3 align-items-center'>
            <div className='px-2'>
                <img className='rounded-circle' src='/user.png' alt={`Photo de l'élève`} height={40} width={40} />
            </div>
            <div className='w-100'>
                <div className='font-weight-bold'>Student Name</div>
                <div className='text-muted small'>Garçon, 13 ans, 5ieme 1</div>
            </div>
            <div className='px-2'>
                <Link to={'/students/5'}>
                    <FontAwesomeIcon className='fa-2x text-info' icon={faEye} />
                </Link>
            </div>
        </div>
    )
}
