import * as React from "react";
import {AppLayout} from "../layouts";
import {StudentProfile} from "../components";
export default function HomeScreen() {

    return (
        <AppLayout>
            <React.Fragment>
                <div className='container'>
                    <h1 className='h6 text-muted'>Liste des apprenants</h1>
                    <StudentProfile />
                    <StudentProfile />
                </div>
            </React.Fragment>
        </AppLayout>
    );
}
