import React, {useState} from "react"
import styles from './InputText.module.css'

type InputTextType = {
    label: string;
    value?: string;
    uid: string;
    type?: string;
    placeholder?: string;
    onChanged: (text: string) => void
}

export default function Input({label, value, onChanged, uid, placeholder, type = 'text'}: InputTextType) {
    const [text, setValue] = useState<string | undefined>(value)

    const handleChanged = (inputText: string) => {
        setValue(inputText)
        onChanged(inputText)
    }
    return (
        <div className='form-group my-2'>
            <label className={styles.label} htmlFor={uid}>{label}</label>
            <input
                type={type}
                id={uid}
                className="form-control text-right bg-light py-2"
                placeholder={placeholder}
                onChange={event => handleChanged(event.target.value)}
                value={text}
            />
        </div>
    )
}
