import {Outlet} from "react-router-dom"
import * as React from "react"
import HomeAppBar from "./HomeAppBar"
import {SimpleAppBar} from "./index"

type AppLayoutType = {
    children: React.ReactNode,
    appBarType?: 'simple' | 'home'
}

export default function AppLayout({children, appBarType = 'simple'}: AppLayoutType) {
    return (
        <React.Fragment>
            <SimpleAppBar />
            <>
                <div className='py-5'>
                    {children}
                    <Outlet />
                </div>
            </>
        </React.Fragment>
    );
}
