import * as React from "react";
import styles from './Student.module.css'
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

type SubjectType = {
    imgUrl?: string,
    fullName?: string,
    humanInfo?: string,
}

export default function MessageSubject({imgUrl, fullName, humanInfo} : SubjectType) {
    return (
        <div className='my-1 border-bottom d-flex bg-light py-2 align-items-center'>
            <div className='px-2'>
                <img className='rounded-circle' src='/user.png' alt={`Photo de l'élève`} height={35} width={35} />
            </div>
            <div className='w-100'>
                <div className='d-flex justify-content-between'>
                    <div className='font-weight-bold'>Sortie des classe</div>
                    <div className='text-muted small'>
                        <small>12/11/2023</small>
                    </div>
                </div>
                <div style={{fontSize:10}} className='text-muted small'>Bonjour , nous vous annonçons qu'a partir du 24/12, ....</div>
            </div>
            <div className='px-2'>
                <Link to={'/messages/5'}>
                    <FontAwesomeIcon className='text-muted' icon={faEye} />
                </Link>
            </div>
        </div>
    )
}
