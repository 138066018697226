import * as React from "react"
import {AppLayout} from "../layouts"
import {useParams} from "react-router"
import {remoteGet} from "../remotes/api"
import {PlanningItem, StudentProfile} from "../components"
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const signal = new AbortController()
export default function StudentScreen() {
    const [value, onChange] = React.useState<Value>(new Date());
    let { studentId } = useParams();

    console.log('date', value)

    React.useEffect(() => {
        return () => {}
    }, [])

    return (
        <AppLayout>
            <React.Fragment>
                <div className='container'>
                    <StudentProfile />
                    <hr/>

                    <h6 className='text-muted mb-0'>Son planning</h6>
                    <div className='text-muted small'>Ci-après le plannig de la semaine de l'élève</div>

                    <PlanningItem />
                    <PlanningItem />
                    <hr/>

                    <h6 className='text-muted mb-0'>Journée scolaire</h6>
                    <div className='text-muted small mb-2'>Sélectionné une date pour consulter la journée scolaire</div>
                    <Calendar onChange={onChange} value={value} />
                </div>
            </React.Fragment>
        </AppLayout>
    )
}
