import * as React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default function SimpleAppBar() {
    return (
        <header className='mb-2'>
            <nav className="navbar bg-white navbar-expand-lg fixed-top mask-custom shadow-0 border-bottom">
                <div className='bg-white w-100 d-flex justify-content-between align-items-center'>
                    <div className='px-3 pt-2 h5'>
                        {/*<img src='/logo.png' alt="Logo Locabu" width={45} />*/}
                        <strong>Note+</strong>
                    </div>
                    <div className='px-3'>
                        <Link to={'/messages'}>
                            <FontAwesomeIcon className='fa-2x text-muted' icon={faEnvelope} />
                        </Link>
                    </div>
                </div>
            </nav>
        </header>
    )
}
