import * as React from "react";
import {GuestLayout} from "../layouts"
import {Button, Input} from "../components"
import {useAuth} from "../contexts/authContext"

const signal = new AbortController()

export default function AuthScreen() {

    const {code, setCode, loading, signIn} = useAuth()

    const [login, setLogin] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')

    const _signIn = async () => {

      if (login?.length === 0 || password?.length === 0) {
          return
      }

     const response = await signIn(login, password, signal)
    }

    console.log('code', code)
    return (

        <GuestLayout>
            <React.Fragment>
                <div className='w-100'>
                    {code && (
                        <>
                            <h1 className='h4 text-center text-uppercase'>Connectez-vous</h1>
                            <div className='text-center small'>Renseignez votre Login et votre mot de passe.</div>

                            <Input
                                label="Login"
                                value={login}
                                placeholder='Ex: Tanou'
                                uid='login'
                                onChanged={ text => setLogin(text)}
                            />

                            <Input
                                label="Password"
                                type='password'
                                value={password}
                                placeholder='Ex: TN12#7'
                                uid='password'
                                onChanged={ text => setPassword(text)}
                            />

                            <div className='my-4'>
                                <Button disabled={loading} theme='primary' title='VALIDER' onClick={ _signIn } />
                            </div>
                        </>
                    )}

                    {! code && (
                        <>
                            <h1 className='h4 text-center text-uppercase'>Code établissement</h1>
                            <div className='text-center small'>Renseignez votre code établissement</div>

                            <Input
                                label="Code"
                                value='code'
                                placeholder='Ex: E01'
                                uid='code'
                                onChanged={ text => {}}
                            />

                            <div className='my-4'>
                                <Button disabled={loading} theme='primary' title='VALIDER' onClick={ () => console.log('submit') } />
                            </div>
                        </>
                    )}
                </div>
            </React.Fragment>
        </GuestLayout>
    );
}
