import * as React from "react";
import styles from './Planning.module.css'

export default function PlanningItem({props} : any) {
    return (
        <div className={styles.cover}>
            <h6 className='mb-0'>Hisoire</h6>
            <div className='small text-muted'>12:00 - 14:85</div>
        </div>
    )
}
