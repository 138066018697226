import * as React from "react";
import styles from './Message.module.css'


export default function MessageForm() {
    return (
        <div className='fixed-bottom'>
            <div className='d-flex'>
                <div className='w-100 p-2 bg-light'>
                    Input here ....
                </div>
                <button className='px-2 shadow text-center bg-primary p-2 border-0'>
                    <img className='' src='/send.png' alt={`Photo de l'élève`} height={30} width={30} />
                </button>
            </div>
        </div>
    )
}
