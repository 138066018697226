import axios from "axios"

axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"
const URL = 'https://noteplus.test/api'
//const URL = 'https://pim.locabu.fr/api'

function post(path, data, controller) {
    return axios.post(URL + '/' + path, data, {signal:controller?.signal}).then(response => response.data)
}

function remotePost(path, data, controller) {
    return axios.post(URL + '/' + path, data, {signal:controller?.signal}).then(response => response.data)
}

function remoteSync(path, data, controller) {
    return axios.post(URL + '/' + path, data, {signal:controller?.signal}).then(response => response.data)
}

function login(data, controller) {
    return axios.post(URL + '/users', data, {signal : controller?.signal}).then(response => response.data)
}

function remoteGet(path, controller) {
    return axios.get(URL + '/' + path, {signal:controller?.signal}).then(response => response.data)
}

export {
    remotePost,
    login,
    remoteGet,
    remoteSync,
    URL,
    post

}
