import * as React from "react";
import {AppLayout} from "../layouts";
import {MessageForm, MessageList, StudentProfile} from "../components";
export default function ChatScreen() {
    return (
        <AppLayout>
            <React.Fragment>
                <div className='container'>
                    <h1 className='h6 text-muted'>Messages</h1>
                    Chat here
                    <MessageForm />
                </div>
            </React.Fragment>
        </AppLayout>
    );
}
