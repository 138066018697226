import * as React from "react";
import {AppLayout} from "../layouts";
import {MessageList, StudentProfile} from "../components";
export default function MessageScreen() {

    return (
        <AppLayout>
            <React.Fragment>
                <div className='container'>
                    <h1 className='h6 text-muted'>Messages</h1>
                    <MessageList />
                </div>
            </React.Fragment>
        </AppLayout>
    );
}
