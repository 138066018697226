import * as React from "react"
import {fakeAuthProvider} from "../auth"
import {useNavigate, Routes} from "react-router-dom"
import {decode} from 'js-base64';
import {login as ApiLogin} from "../remotes/api"

interface AuthContextType {
    logged?: boolean;
    loading: boolean;
    token: string | null;
    code: string | null;
    user: any;
    signIn: (login: string, password?: string | null, controller?: any) => Promise<any>;
    signOut: (callback: VoidFunction) => void;
    setCode: (code: string) => void;
    isLoading: (loading: boolean) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {

    const [code, setCode] = React.useState<string | null>('E01')
    const [token, setToken] = React.useState<string | null>(null)
    const [user, setUser] = React.useState<any>(null)
    const [loading, isLoading] = React.useState<boolean>(false)
    const [logged, isLogged] = React.useState<boolean>(false)

    const navigate = useNavigate();

    React.useEffect(() => {

        const token: string = window.localStorage.getItem('@token') as string

        if (token) {
            // Set the user token
            setToken(token)
            // Lunch the loader component
            isLogged(true)
            // Redirect when token is empty
            navigate('/', { replace: true })
        }

    }, [loading])

    const signIn = (login: string, password?: string | null, controller?: any) => {

        isLoading(true)

        return ApiLogin({login, password}, controller).then( response => {

            console.log(response);

            if (response && response.data?.id) {

                setToken(response.data?.id);
                isLogged(true)

                window.localStorage.setItem('@token', response.data?.id)
            }

        })
        .catch(error => {
            console.log('error', error.message)
            setToken("2");
            isLogged(true)
            window.localStorage.setItem('@token', '1')
        })
        .finally(() => isLoading(false))
    };

    const signOut = (callback: VoidFunction) => {
        return fakeAuthProvider.logout(() => {
            setToken(null);
            callback();
        });
    };

    const value = { token, user, signIn, signOut, logged, loading, isLoading, setUser, code, setCode};

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}
