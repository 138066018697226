import * as React from "react";
import styles from './Message.module.css'
import MessageSubject from "./MessageSubject"

export default function MessageList() {
    return (
        <>
            {[1, 2, 3, 4, 6, 7, 8, 9].map((subject: any, key: number ) => (
                <MessageSubject key={key} />
            ))}
        </>
    )
}
